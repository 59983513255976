import { ReactElement } from "react";
import styles from "./styles.module.scss";
import { TStandAloneImage } from "@page-builder/ts/generated";

export default function ScrollToTop({
    props = {},
}: {
    props?: TStandAloneImage["props"];
}): ReactElement {
    return (
        <div className={styles["image-container"]}>
            <img src={props.image} alt={props.imageAlt} loading="lazy" />
        </div>
    );
}
